import '../styles/globals.css';
import App from 'next/app';
import Script from 'next/script';
import PropTypes from 'prop-types';
import {PlasmicQueryDataProvider} from '@plasmicapp/react-web/lib/query';
import {PlasmicRootProvider} from '@plasmicapp/react-web';
import BuildComponetTree from '../lib/utils/buildCompnentTree';
import I18nWrapper, {activate, asyncActivate} from '../lib/i18n';
import extractPlasmicQueryData, {isInStudio} from '../lib/plasmic-utils';
import UserProvider from '../context/UserContext';
import {AnalyticsProvider} from '../lib/analytics';
import PriceProvider from '../context/PriceContext';
import ExperimentProvider from '../context/ExperimentContext';
import CookiesProvider from '../context/Cookies/CookiesContext';
import CouponLoader from '../components/Coupon/CouponLoader';
import SsoPopup from '../components/Sso/SsoPopup';
import NotificationsProvider from '../context/NotificationsContext';
import VariantProvider from '../context/VariantContext';
import CtaVariantHelper from '../lib/utils/CtaVariantHelper';
import ScheduledVariantHelper from '../lib/utils/ScheduledVariantHelper';
import CountryHelper, {getCountryCode} from '../lib/utils/CountryHelper';
import {CampaignProvider} from '../context/CampaignContext';
import ToastNotificationProvider from '../components/UI/Molecules/ToastNotification/Context';
import ToastNotificationManager from '../components/UI/Molecules/ToastNotification/Manager';
import CookiebotManager from '../components/Cookies/CookiebotManager';
import {getRegionCode, parseRegionVariant} from '../lib/utils/region';

export default function Page({Component, pageProps, router, prepassData, cookies, headers}) {
  if (!isInStudio(router.pathname)) {
    import('../lib/datadog');
    typeof window !== 'undefined' && import('../lib/envato-sso');
  }

  activate(router?.locale || 'en');
  const country = getCountryCode(cookies, headers);

  // prettier-ignore
  const Providers = [
    [I18nWrapper],
    [PlasmicRootProvider],
    [PlasmicQueryDataProvider, {prefetchedCache: prepassData.queryCache}],
    [NotificationsProvider],
    [UserProvider],
    [PriceProvider],
    [CookiesProvider, {reqCookies: cookies}],
    [ExperimentProvider],
    [AnalyticsProvider],
    [CampaignProvider],
    [ToastNotificationProvider],
    [
      VariantProvider,
      {
        variations: {
          country: country.toLowerCase(),
          region: parseRegionVariant(getRegionCode(country)),
        },
        helpers: [
          <CtaVariantHelper key="sub" />,
          <ScheduledVariantHelper key="sch" prerender={ScheduledVariantHelper.prerender} />,
          <CountryHelper key="country" prerender={CountryHelper.prerender} data={country} />,
        ],
      },
    ],
  ];

  if (typeof window !== 'undefined' && window.location.host !== 'placeit.net') {
    console.log('_app.jsx render (not shown in prod):', Date.now());
  }

  return (
    <BuildComponetTree providers={Providers}>
      <Component {...pageProps} />
      <CouponLoader />
      <SsoPopup />
      <ToastNotificationManager />
      <CookiebotManager />
      <Script id="page-metadata">
        {`window.pageMetadata=${JSON.stringify(prepassData.pageMetadata)}`}
      </Script>
    </BuildComponetTree>
  );
}

Page.getInitialProps = async appContext => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const {Component, pageProps, router, ctx} = appContext;
  const {req} = ctx;

  await asyncActivate(router?.locale || 'en');

  const wrappedComponent = (
    <I18nWrapper>
      <PlasmicRootProvider>
        <Component {...pageProps} />
      </PlasmicRootProvider>
    </I18nWrapper>
  );
  const prepassData = await extractPlasmicQueryData(wrappedComponent);
  const appProps = await App.getInitialProps(appContext);
  const cookies = req
    ? req.cookies
    : document.cookie.split('; ').reduce((prev, current) => {
        const [name, ...value] = current.split('=');
        prev[name] = value.join('=');
        return prev;
      }, {});
  const headers = req ? req.headers : '';
  return {
    ...appProps,
    prepassData,
    cookies,
    headers,
  };
};

Page.defaultProps = {
  Component: null,
  pageProps: {},
  router: {},
  prepassData: {
    queryCache: {},
    pageMetadata: {},
  },
  cookies: {},
  headers: {},
};
Page.propTypes = {
  Component: PropTypes.elementType,
  pageProps: PropTypes.shape({}),
  // router: PropTypes.instanceOf(NextRouter),
  router: PropTypes.object /* eslint react/forbid-prop-types: 0 */,
  prepassData: PropTypes.shape({
    queryCache: PropTypes.shape({}),
    pageMetadata: PropTypes.shape({}),
  }),
  cookies: PropTypes.shape({}),
  headers: PropTypes.object,
};
