/*
  IMPORTANT NOTE:
  This file is modified by AST at build time.
*/
import React, { createContext, memo } from 'react';
import CtaLevel____placeit from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant__CtaLevel';
import TestExpAbCd3Fg1____placeit from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant__TestExpAbCd3Fg1';
import _010119700000123129992359____placeit from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant___010119700000123129992359';
import Region____placeit from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant__Region';
import _122620240501010120250600____placeit from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant___122620240501010120250600';
import C1RWdyOnVipExbhYAf____placeit from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant__C1RWdyOnVipExbhYAf';
import _010920251500021020252050____placeit from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant___010920251500021020252050';
import _021020250600030920250600____placeit from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant___021020250600030920250600';
import _022020251400031420252300____placeit from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant___022020251400031420252300';
import _021820250600031120250600____placeit from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant___021820250600031120250600';
import Country____placeit_landing_pages from '../plasmic_components/plasmic/placeit_landing_pages/PlasmicGlobalVariant__Country';
import _032720241400041620240600____placeit_home_extras from '../plasmic_components/plasmic/placeit_home_extras/PlasmicGlobalVariant___032720241400041620240600';
import _040420231000042820231000____placeit_home_extras from '../plasmic_components/plasmic/placeit_home_extras/PlasmicGlobalVariant___040420231000042820231000';
import _040220251400053020250600____placeit_home_extras from '../plasmic_components/plasmic/placeit_home_extras/PlasmicGlobalVariant___040220251400053020250600';
import _041620251400050720250600____placeit_home_extras from '../plasmic_components/plasmic/placeit_home_extras/PlasmicGlobalVariant___041620251400050720250600';
import PropTypes from 'prop-types';
// insert imports

// in addition to the variant contexts needed by plasmic... we can use this
// single variant context to hold all experiments... easier to use in our code.
export const VariantContext = createContext({
  variations: {},
  setVariations: async () => null,
  ctalevel: null,
  testexpabcd3fg1: null,
  _010119700000123129992359: null,
  region: null,
  _122620240501010120250600: null,
  c1rwdyonvipexbhyaf: null,
  _010920251500021020252050: null,
  _021020250600030920250600: null,
  _022020251400031420252300: null,
  _021820250600031120250600: null,
  country: null,
  _032720241400041620240600: null,
  _040420231000042820231000: null,
  _040220251400053020250600: null,
  _041620251400050720250600: null
});
const PlasmicVariants = memo(class extends React.Component {
  render() {
    if (typeof window !== 'undefined' && window.location.host !== 'placeit.net') console.log('PlasmicVariants Render', Date.now());
    return <VariantContext.Provider value={this.props}><_041620251400050720250600____placeit_home_extras.Provider value={this.props._041620251400050720250600____placeit_home_extras}>
     
   <_040220251400053020250600____placeit_home_extras.Provider value={this.props._040220251400053020250600____placeit_home_extras}>
     
   <_040420231000042820231000____placeit_home_extras.Provider value={this.props._040420231000042820231000____placeit_home_extras}>
     
   <_032720241400041620240600____placeit_home_extras.Provider value={this.props._032720241400041620240600____placeit_home_extras}>
     
   <Country____placeit_landing_pages.Provider value={this.props.Country____placeit_landing_pages}>
     
   <_021820250600031120250600____placeit.Provider value={this.props._021820250600031120250600____placeit}>
     
   <_022020251400031420252300____placeit.Provider value={this.props._022020251400031420252300____placeit}>
     
   <_021020250600030920250600____placeit.Provider value={this.props._021020250600030920250600____placeit}>
     
   <_010920251500021020252050____placeit.Provider value={this.props._010920251500021020252050____placeit}>
     
   <C1RWdyOnVipExbhYAf____placeit.Provider value={this.props.C1RWdyOnVipExbhYAf____placeit}>
     
   <_122620240501010120250600____placeit.Provider value={this.props._122620240501010120250600____placeit}>
     
   <Region____placeit.Provider value={this.props.Region____placeit}>
     
   <_010119700000123129992359____placeit.Provider value={this.props._010119700000123129992359____placeit}>
     
   <TestExpAbCd3Fg1____placeit.Provider value={this.props.TestExpAbCd3Fg1____placeit}>
     
   <CtaLevel____placeit.Provider value={this.props.CtaLevel____placeit}>
     {this.props.children}
   </CtaLevel____placeit.Provider>
  
   </TestExpAbCd3Fg1____placeit.Provider>
  
   </_010119700000123129992359____placeit.Provider>
  
   </Region____placeit.Provider>
  
   </_122620240501010120250600____placeit.Provider>
  
   </C1RWdyOnVipExbhYAf____placeit.Provider>
  
   </_010920251500021020252050____placeit.Provider>
  
   </_021020250600030920250600____placeit.Provider>
  
   </_022020251400031420252300____placeit.Provider>
  
   </_021820250600031120250600____placeit.Provider>
  
   </Country____placeit_landing_pages.Provider>
  
   </_032720241400041620240600____placeit_home_extras.Provider>
  
   </_040420231000042820231000____placeit_home_extras.Provider>
  
   </_040220251400053020250600____placeit_home_extras.Provider>
  
   </_041620251400050720250600____placeit_home_extras.Provider></VariantContext.Provider>
  }
});
PlasmicVariants.propTypes = {
  children: PropTypes.node.isRequired
};
export default PlasmicVariants;
export const ContextNames = ["CtaLevel____placeit", "TestExpAbCd3Fg1____placeit", "_010119700000123129992359____placeit", "Region____placeit", "_122620240501010120250600____placeit", "C1RWdyOnVipExbhYAf____placeit", "_010920251500021020252050____placeit", "_021020250600030920250600____placeit", "_022020251400031420252300____placeit", "_021820250600031120250600____placeit", "Country____placeit_landing_pages", "_032720241400041620240600____placeit_home_extras", "_040420231000042820231000____placeit_home_extras", "_040220251400053020250600____placeit_home_extras", "_041620251400050720250600____placeit_home_extras", "ctalevel", "testexpabcd3fg1", "_010119700000123129992359", "region", "_122620240501010120250600", "c1rwdyonvipexbhyaf", "_010920251500021020252050", "_021020250600030920250600", "_022020251400031420252300", "_021820250600031120250600", "country", "_032720241400041620240600", "_040420231000042820231000", "_040220251400053020250600", "_041620251400050720250600"];