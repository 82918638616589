import {PlasmicPrepassContext} from '@plasmicapp/react-web/lib/query';
import {Component} from 'react';
import prepass from 'react-ssr-prepass';
import PropTypes from 'prop-types';
// import {i18n} from '@lingui/core';
// import {Trans} from '@lingui/react';
import {Trans} from './i18n';

/**
 described here: https://docs.plasmic.app/learn/localization-frameworks/
 must be passed as translator prop to PlasmicRootProvider
*/
/* eslint-disable react/destructuring-assignment */
export function PlasmicTranslator(key, opts) {
  if (opts?.components) {
    return <Trans id={key} components={opts.components} />;
  }
  // return i18n._(key);
  return key;
}
/* eslint-enable */

export default async function extractPlasmicQueryData(element) {
  const cache = new Map();
  let pageMetadata = {};
  try {
    pageMetadata = await plasmicPrepass(
      <PlasmicPrepassContext cache={cache}>{element}</PlasmicPrepassContext>
    );
  } catch (err) {
    console.warn(`PLASMIC: Error encountered while pre-rendering`, err);
  }
  const queryCache = Object.fromEntries(
    Array.from(cache.entries()).filter(
      ([key, val]) => !key.startsWith('$swr$') && val !== undefined
    )
  );
  return {queryCache, pageMetadata};
}

/**
 * Runs react-ssr-prepass on `element`
 */
async function plasmicPrepass(element) {
  let pageMetadata = {};
  await prepass(buildPlasmicPrepassElement(element), el => {
    if (el.type.metadata) {
      pageMetadata = el.type.metadata;
    }
  });
  return pageMetadata;
}

/**
 * Unfortunately in codegen we can't check for `PlasmicComponent` instances,
 * making it harder to isolate components in error boudaries to fetch as much
 * data as possible.
 */
function buildPlasmicPrepassElement(element) {
  return <GenericErrorBoundary>{element}</GenericErrorBoundary>;
}

class GenericErrorBoundary extends Component {
  constructor(props) {
    super(props);
    const {children} = props;
    this.children = children;
  }

  componentDidCatch(error) {
    console.log(`Plasmic: Encountered error while prepass rendering:`, error);
  }

  render() {
    return this.children;
  }
}

GenericErrorBoundary.defaultProps = {
  children: null,
};
GenericErrorBoundary.propTypes = {
  children: PropTypes.element,
};

/**
 * Used to selectively choose which features to run in the Plasmic Studio
 */
export function isInStudio(pathname) {
  if (typeof window === 'undefined') return false;
  try {
    if (!pathname) pathname = window?.location?.pathname;
  } catch (e) {
    // pass
  }
  return String(pathname).indexOf('/plasmic_host') === 0;
}
